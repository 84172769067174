<template>
  <v-container>
    <v-card class="pa-5">
      <div v-if="searching">
        <p class="text-center mt-5">
          <v-icon>fas fa-atom fa-spin</v-icon> Carregando ...
        </p>
      </div>
      <v-row>
        <v-col cols="12">
          <joker />
        </v-col>
        <v-col cols="4">
          <v-text-field
            @keyup.enter="fetchSearch()"
            :disabled="searching"
            label="NOME %"
            v-model="search"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            @keyup.enter="fetchSearch()"
            :disabled="searching"
            label="ID (asin)"
            v-model="ASIN"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            @keyup.enter="fetchSearch()"
            :disabled="searching"
            label="Itens POR PÁGINA"
            max="500"
            type="number"
            v-model="limit"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-select
            :disabled="searching"
            v-model="lstSearchStoreID"
            multiple
            clearable
            :items="stores"
            item-value="id"
            item-text="name"
            label="Loja"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <h3>Regras de preçoes</h3>
        </v-col>
        <v-col cols="8">
          <v-select
            :disabled="searching"
            v-model="PRICE_PERC_DESC_OPERATOR"
            :items="lstPricePercOperators"
            item-value="operator"
            item-text="name"
            label="Operador"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-text-field
            @keyup.enter="fetchSearch()"
            :disabled="searching"
            label="Percentual de diferença de preço (DESCONTO DEVE SER NEGATIVO)"
            type="number"
            clearable
            max="99"
            v-model="PRICE_PERC_DESC"
          ></v-text-field>
        </v-col>
        <v-col cols="5">
          <v-text-field
            @keyup.enter="fetchSearch()"
            :disabled="searching"
            label="Filtro da hora de inserção do preço atual"
            type="number"
            clearable
            max="99"
            v-model="PRICE_UPDATED_AT_HOUR"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="text-right">
          <v-btn :disabled="searching" color="success" @click="fetchSearch()"
            >Pesquisar</v-btn
          >
        </v-col>
        <v-col cols="12">
          <template
            v-if="searchResult && searchResult.data && searchResult.data"
          >
            <v-card>
              <v-card-title>
                Produtos
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="searchResults"
                  append-icon="fas fa-search"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <table-of-products
                :products="searchResult.data"
                :search="searchResults"
              />
            </v-card>
          </template>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-model="dialog" width="700">
      <v-card v-if="selectedProd">
        <v-card-title class="headline" primary-title>
          {{ selectedProd.title }}
        </v-card-title>
        <v-card-text class="text-center">
          URL
          <v-textarea
            readonly
            v-model="selectedProd.url"
            id="selectedProdUrl"
            @click="copyTestingCode('selectedProdUrl')"
            rows="2"
          ></v-textarea>
          <img
            style="cursor: pointer;"
            :src="selectedProd.thumbnail"
            @click="copyTestingCode('selectedProdUrl')"
          />
          <p class="primary white--text">
            Clique na imagem ou na URL para copiar a URL
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import TableOfProductsVue from './../components/TableOfProducts'
import JokerVue from './../components/Joker'
// @ is an alias to /src
export default {
  name: 'amz',
  components: {
    'table-of-products': TableOfProductsVue,
    joker: JokerVue
  },
  data() {
    return {
      selectedProd: null,
      dialog: false,
      search: null,
      ASIN: null,
      PRICE_PERC_DESC: null,
      PRICE_PERC_DESC_OPERATOR: null,
      PRICE_UPDATED_AT_HOUR: null,
      lstSearchStoreID: [],
      stores: [],
      limit: 10,
      prime: false,
      searchResults: null,
      mainPage: null,
      searchResult: null,
      searching: false,
      headers: [
        { text: 'ID', value: 'asin' },
        { text: 'Nome', value: 'title' },
        { text: 'Preço', value: 'ProductPrice.price' },
        { text: '%', value: 'ProductPrice.perc_desc' },
        {
          text: 'Sem desconto (de x por y)',
          value: 'ProductPrice.before_discount'
        }
      ],
      lstPricePercOperators: [
        { name: '> (Maior que)', operator: '>'},
        { name: '< (Menor que)', operator: '<'},
        { name: '>= (Maior ou igual a)', operator: '>='},
        { name: '<= (Menor ou igual a)', operator: '<='},
        { name: '= (Igual a)', operator: '='}
      ]
    }
  },
  mounted() {
    this.fetchStores()
  },
  created() {},
  methods: {
    copyTestingCode(_elementID) {
      var vm = this
      let testingCodeToCopy = document.querySelector(`#${_elementID}`)
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()

      try {
        if (document.execCommand('copy')) {
          vm.$snotify.success(`Texto copiado para a área de transferência`)
        } else {
          throw new Error(
            'Não foi possível copiar o texto para área de transferência. Copie manualmente'
          )
        }
      } catch (_err) {
        vm.$snotify.error(`Oops... ${_err.message}`)
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
    fetchSearch() {
      var vm = this
      vm.searching = true
      let _storeIds =
        vm.lstSearchStoreID && vm.lstSearchStoreID.length > 0
          ? vm.lstSearchStoreID.join(',')
          : null
      vm.$API
        .Request('post', `products/list`, {
          title: vm.search,
          limit: vm.limit,
          asin: vm.ASIN,
          perc_desc: vm.PRICE_PERC_DESC,
          perc_desc_operator: vm.PRICE_PERC_DESC_OPERATOR,
          price_updated_at: vm.PRICE_UPDATED_AT_HOUR,
          store_ids: _storeIds
        })
        .then(result => {
          console.debug(result)
          vm.searchResult = result
          vm.searching = false
        })
        .catch(error => {
          vm.searching = false
          console.debug(error)
          vm.$snotify.error(error.message)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    },
    fetchStores() {
      var vm = this
      vm.searching = true
      vm.$API
        .Request('post', `stores/list`)
        .then(result => {
          if (result && result.data) {
            vm.stores = result.data
          }
          vm.searching = false
        })
        .catch(error => {
          vm.searching = false
          console.debug(error)
          vm.$snotify.error(error.message)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    },
    openItem(_i) {
      var vm = this
      vm.selectedProd = _i
      vm.dialog = true
    }
  }
}
</script>
